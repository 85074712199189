@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Neue Haas Grotesk Display Pro Light";
  src: url("../src/fonts/NeueHass/NeueHaasDisplayLight.ttf");
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro Roman";
  src: url("../src/fonts/NeueHass/NeueHaasDisplayRoman.ttf");
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro Bold";
  src: url("../src/fonts/NeueHass/NeueHaasDisplayBold.ttf");
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro Medium";
  src: url("../src/fonts/NeueHass/NeueHaasDisplayMediu.ttf");
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro Black";
  src: url("../src/fonts/NeueHass/NeueHaasDisplayBlack.ttf");
}

.Neue {
  font-family: "Neue Haas Grotesk Display Pro Roman";
}

.NeueMedium {
  font-family: "Neue Haas Grotesk Text Pro Medium";
}

.NeueBold {
  font-family: "Neue Haas Grotesk Display Pro Bold";
}

.NeueLight {
  font-family: "Neue Haas Grotesk Display Pro Light";
}

.NeueBlack {
  font-family: "Neue Haas Grotesk Text Pro Black";
}

.Splash {
  background: linear-gradient(to bottom, #888888, #000000, #ffffff);
}

* {
  letter-spacing: 1.5% !important;
}

/* index.css */
body {
  /* Set the default scrollbar width and color */
  scrollbar-width: thin;
  scrollbar-color: purple transparent;

  /* Set the width and color of the thumb (the draggable part of the scrollbar) */
  /* The first color (purple) represents the thumb color, the second (transparent) represents the track color */
  scrollbar-color: purple transparent;
}

/* Define the scrollbar styles for WebKit-based browsers */
/* You can adjust the height and color to your preference */
/* The first color (purple) represents the thumb color, the second (transparent) represents the track color */
body::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: purple; /* Set the color of the scrollbar thumb */
  border-radius: 3px; /* Round the corners of the thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #a000a0; /* Change the color on hover (optional) */
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}
